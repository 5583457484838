.container {
    background-color: #0C4F7E;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 100px 0;
    }
}

.form-wrapper {
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tally-form {
    width: 60%;
    height: 500px;
    border: 0;
    margin: 0;
}